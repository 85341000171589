
import { Component, Vue } from "vue-property-decorator";
import OrderApi, { SellCryptoOrderRequest } from "@/services/api/order";
import { AxiosError, AxiosResponse } from "axios";
import ErrorService, { ValidationErrors } from "@/services/errors";
import Order from "@/models/order";
import { mdiCheck, mdiCurrencyUsd } from "@mdi/js";
import { Action, Getter } from "vuex-class";
import { User } from "@/models/user";
import UserApi from "@/services/api/user";
import firebase, {
    addAnalyticsEvent,
    ensureAuthenticated,
} from "@/plugins/firebase";
import { captureSentryException, captureSentryMessage } from "@/plugins/sentry";
import StatusCodes from "http-status-codes";
import CompositeAlert from "@/components/CompositeAlert.vue";
import UserProfile from "@/models/user-profile";
import Settings from "@/models/settings";
import SettingsApi from "@/services/api/settings";
import { ApiResponse } from "@/services/api/axios";

@Component({
    components: {
        CompositeAlert,
    },
})
export default class CryptoSell extends Vue {
    formEmail: string = "";
    formPhoneNumber: string = "";
    formInputErrors: ValidationErrors = new ValidationErrors();
    isSubmitting: boolean = false;
    isValidatingBillNumber = false;
    formAmount: number = 2;
    dollarIcon: string = mdiCurrencyUsd;
    tickIcon: string = mdiCheck;
    settings: Settings | null = null;

    @Getter("user") user!: User | null;
    @Getter("userIsAnonymous") userIsAnonymous!: boolean;
    @Getter("authToken") authToken!: string;
    @Getter("userIsAdmin") userIsAdmin!: boolean;
    @Getter("referralCode") referralCode!: string;
    @Action("setUser") setUser!: (user: User | null) => void;
    @Action("setAuthToken") setAuthToken!: (authToken: string | null) => void;

    exchangeCrypto() {
        addAnalyticsEvent("exchange_crypto_clicked");
        this.isSubmitting = true;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleError = (error: any) => {
            this.isSubmitting = false;
            this.handleError(error);
        };

        this.$recaptcha("buy_airtime")
            .then((token: string) => {
                const payload: SellCryptoOrderRequest = {
                    captcha: token,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    phone_number: this.formPhoneNumber,
                    email: this.formEmail,
                    amount: parseFloat(this.formAmount.toString()),
                };
                this.ensureUserExists()
                    .then(() => {
                        this.createOrder(payload);
                    })
                    .catch(handleError);
            })
            .catch(handleError);
    }

    setProfileFromAuthUser() {
        this.formEmail = this.user?.email || "";
    }

    loadProfile() {
        if (!firebase.auth().currentUser?.uid) {
            captureSentryException(
                "cannot load profile for an unauthenticated user"
            );
            return;
        }

        UserApi.getProfile(firebase.auth().currentUser?.uid as string)
            .then((response: AxiosResponse) => {
                const userProfile = new UserProfile(response.data.data);
                this.formPhoneNumber = userProfile.paymentPhoneNumber ?? "";
                this.formEmail = userProfile.email ?? this.formEmail;
            })
            .catch((error: AxiosError<ApiResponse>) => {
                if (error.response?.status === StatusCodes.NOT_FOUND) {
                    this.setProfileFromAuthUser();
                    return;
                }
                this.handleAxiosError(error);
            });
    }

    mounted() {
        if (this.user || this.userIsAnonymous) {
            ensureAuthenticated()
                .then(() => {
                    this.loadProfile();
                })
                .catch(this.handleError);
        }
        this.loadSettings();
    }

    loadSettings(): Promise<void> {
        return SettingsApi.get()
            .then((response: AxiosResponse) => {
                this.settings = new Settings(response.data.data);
            })
            .catch(this.handleAxiosError);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError(error: any) {
        if (error instanceof Error && error.message) {
            captureSentryException(error);
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.ERROR,
                error.message
            );
            return;
        }
        captureSentryMessage(error);
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            "We could not carry out your request. It may be because your internet connection is not so good. Please try again"
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ensureUserExists(): Promise<any> {
        if (this.authToken) {
            return new Promise<boolean>((resolve) => resolve(true));
        }
        return firebase
            .auth()
            .signInAnonymously()
            .then(async (response) => {
                this.setUser(response.user);
                this.setAuthToken((await response.user?.getIdToken()) || null);
                addAnalyticsEvent("anonymous_user_created_before_order");
            });
    }

    createOrder(payload: SellCryptoOrderRequest) {
        OrderApi.createCryptoSell(payload)
            .then((response: AxiosResponse) => {
                const order = new Order(response.data.data);
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    "Your order has been created successfully"
                );

                addAnalyticsEvent("begin_checkout", {
                    currency: "USD",
                    value: order.paymentAmount,
                    items: [
                        {
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            item_id: order.itemId,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            item_name: order.itemName,
                            affiliation: "CRYPTO",
                            currency: "USD",
                        },
                    ],
                });

                this.$router.push({
                    name: this.$constants.ROUTE_NAMES.ORDERS_SHOW,
                    params: {
                        orderId: order.orderId,
                    },
                });
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isSubmitting = false;
            });
    }

    handleAxiosError(error: AxiosError<ApiResponse>) {
        this.formInputErrors = ErrorService.getValidationErrors(error);
        this.handleError(
            new Error(error?.response?.data?.message ?? error.message)
        );
    }
}
